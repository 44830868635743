<template>
  <section v-if="isReady">
    <div class="container" v-if="$can('manage', 'DadoEscrita')">
      <EquipmentDataPanel
        :equipment="equipment"
        :display="display"
        :panelName="panelName"
        :panel="panel"
        :refreshTimerEnabled="true"
      >
      </EquipmentDataPanel>
    </div>
    <InfoBox v-else preset="unauthorized" />
  </section>
</template>

<script>
import EquipmentDashboardBase from "@/views/private/DashboardEquipmentBase.vue";
import Panels from "@/assets/dashboard/panels.json";
import EquipmentDataPanel from "@/components/equipment-data-panel.vue";
import InfoBox from "@/components/info-box.vue";

export default {
  name: "DashboardDataEditor",
  extends: EquipmentDashboardBase,
  components: {
    EquipmentDataPanel,
    InfoBox
  },
  computed: {
    panel() {
      // get the screen panel
      var panel = null;
      if ((this?.display?.panels || [])?.length) {
        panel = this.display.panels.find(
          ({template}) => template == "EquipmentDataPanel"
        );
      }
      if (!panel) {
        let cfg = Panels.find(
          (item) => item.template.template == "EquipmentDataPanel"
        );
        if (cfg && cfg.template) {
          panel = JSON.parse(JSON.stringify(cfg.template));
        }
      }
      return panel;
    }
  },
  watch: {
    templateState(n, o) {
      if (o == "loading" && n == "ready") {
        this.setDisplay();
      }
    }
  },
  methods: {
    fetchEquipmentDataList() {
      var equipmentId = this?.equipment?.id || this.equipmentId;
      if (!equipmentId) return;
      var query = {
        resource: "data",
        connectorId: equipmentId,
        forceUpdate: true
      };
      this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    onMQTTStatus(status) {
      if (status == "CONNECTED") {
        this.$rt.mqtt.subscribeOnlyOnTopics(
          Object.keys(this.mqttConnectorTopics || {}).map((t) => `${t}/#`)
        );
      }
    },
    async setup() {
      this.fetchEquipmentDataList();
      this.source = {
        resourceIds: []
      };
      this.enableMQTT(true);
      this.parseMQTTConnectorTopics();
      if (this.mqttConnectorTopics) {
        this.onMQTTStatus(this.$rt.mqtt.status);
      }
    }
  },
  created() {
    // this.fetchEquipmentDataList();
  },
  beforeDestroy() {
    this.enableMQTT(false);
  }
};
</script>

<style scoped>
.nav-tabs-custom > .tab-content {
  max-height: 100% !important;
}
</style>
