<template>
  <div
    class="me"
    :class="{clicable: isEditing}"
    @click="$emit('click', $event)"
  >
    <i v-if="iconClass" :class="iconClass"></i>
    <span class="hidden-xs">{{ $t(title) }}</span>
  </div>
</template>

<script>
const panelTitle = ($vm) => {
  let res =
    $vm?.panel?.title && /[${}]/.test($vm?.panel?.title)
      ? $vm.$utils.evaluate($vm.allowedJSONContext, $vm?.panel?.title)
      : $vm?.panel?.title || "";
  return $vm.$utils.trim(res);
};
export {panelTitle};
export default {
  name: "DashboardPanelTitle",
  props: {
    panel: {
      type: Object,
      required: true
    },
    connector: {
      type: Object,
      required: false,
      default: () => null
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    dftPanels() {
      return this.$store.getters["dashboard/allPanels"] || [];
    },
    iconClass() {
      // it keeps backward compatibility
      // null = user has explicity removed the icon, so do not get it from the original panel
      // ""   = old screens = get the original panel icon, if there is one
      return this?.panel?.icon === null
        ? ""
        : this?.panel?.icon ||
            (this.dftPanels.find(
              ({template}) => template.template == this.panel.template
            )?.template?.icon ??
              "");
    },
    allowedJSONContext() {
      return {
        system: this.$store.getters.systemProperties,
        connector: this.connector || {}
      };
    },
    title() {
      return panelTitle(this);
    }
  }
};
</script>

<style scoped>
.me {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0;
  font-size: 18px;
  color: #444;
  min-height: 26px;
}
.me > i {
  font-size: 90%;
  margin-right: 5px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}
</style>